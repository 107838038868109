import { Image as NativeImage } from 'react-native'

import { FormattedMessage } from 'react-intl'

import RimsImage from 'app/assets/images/rims.png'
import Box from 'app/components/base/Box/Box'
import Image from 'app/components/base/Image'
import Stack from 'app/components/base/Stack'
import Text from 'app/components/base/Text'
import { IS_NATIVE_APP } from 'app/utils/constants/env.constants'
import { getStaticImageSrc } from 'app/utils/helpers/functions.helpers'

import CheckContainer from './CheckContainer'

const CheckVideo = () => {
  return (
    <CheckContainer>
      <Stack
        sx={{
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%'
        }}
      >
        <Text
          variant="h1"
          color="common.white"
          sx={{
            fontWeight: { xs: 700, md: 400 },
            lineHeight: IS_NATIVE_APP ? 58 : { xs: '58px', md: '65px' },
            zIndex: 1,
            textAlign: 'center',
            mx: 3
          }}
        >
          <FormattedMessage defaultMessage="Explore our video & blogs" />
        </Text>

        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            ...(IS_NATIVE_APP && {
              display: 'flex',
              alignItems: 'center'
            }),
            ...(!IS_NATIVE_APP && {
              maxWidth: 350,
              '& > span': {
                width: '100% !important',
                height: '100% !important'
              }
            })
          }}
        >
          {!IS_NATIVE_APP ? (
            <Image
              src={getStaticImageSrc(RimsImage)}
              layout="responsive"
              alt="rims"
              height={254}
              width={350}
              sizes="384px"
            />
          ) : (
            <NativeImage
              source={require('app/assets/images/rims.png')}
              style={{
                resizeMode: 'cover',
                width: 350,
                height: 254
              }}
            />
          )}
        </Box>
      </Stack>
    </CheckContainer>
  )
}

export default CheckVideo

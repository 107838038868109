import { GetStaticProps } from 'next'
import React, { ReactElement } from 'react'

import { dehydrate, QueryClient } from 'react-query'

import { userConfig } from 'app/services/Query/user/user.query'
import { vehicleConfig } from 'app/services/Query/vehicle/vehicle.query'
import { NextPage } from 'app/types/decalarations/next'

import MainLayout from '~/components/layouts/MainLayout'
import HomeView from '~/views/HomeView/HomeView'

const HomePage: NextPage = () => <HomeView />

HomePage.getLayout = (page: ReactElement) => <MainLayout>{page}</MainLayout>

HomePage.getMeta = (intl) => ({
  title: intl.formatMessage({
    defaultMessage: 'Auto Parts, Car Parts and Automotive Accessories'
  }),
  description: intl.formatMessage({
    defaultMessage:
      'IFnd Autoparts. Purchasing items has never been easier, quicker and smarter.'
  })
})

export const getStaticProps: GetStaticProps = async () => {
  try {
    const queryClient = new QueryClient()

    await queryClient.prefetchQuery(
      userConfig.getVideos.key,
      userConfig.getVideos.request
    )

    await queryClient.prefetchQuery(
      vehicleConfig.getVehicleUniversalParams.key,
      vehicleConfig.getVehicleUniversalParams.request
    )

    return {
      props: {
        dehydratedState: dehydrate(queryClient)
      }
    }
  } catch (error) {
    return { notFound: false, props: {} }
  }
}

export default HomePage
